@import './vars.css';
.module-border-wrap {
    padding: 1rem;
    position: relative;
    background: linear-gradient(45deg, var(--extra-color), var(--dark-color));
    padding: 3px;
  }
  
  .module {
    background: var(--secondary-color);
    color: white;
    padding: 2rem;
  }