@import './vars.css';

a.btn.cta {
    display: inline-block;
    position: relative;
    color: var(--light-color);
    background-color: var(--secondary-color);
}

a.btn.cta .icon {
    fill: var(--light-color);
    stroke: var(--light-color);
    height: 24px;
    width: 24px;
}

a.btn.cta:hover {
    display: inline-block;
    position: relative;
    color: var(--primary-color);
    background-color: var(--light-color);
}

a.btn.cta:hover .icon {
    fill: var(--primary-color);;
    stroke: var(--primary-color);
}

a.btn.cta.hover-underline {
    color: var(--secondary-color);
    background-color: transparent;
    border-radius: 0;
}

a.btn.cta.hover-underline .icon {
    fill: var(--secondary-color);
    stroke: var(--secondary-color);
}

a.btn.cta.hover-underline:hover {
    color: var(--primary-color);
}

a.btn.cta.hover-underline:hover .icon {
    fill: var(--primary-color);
    stroke: var(--primary-color);
}

a.btn.cta.hover-underline:focus {
    color: var(--extra-color);
    box-shadow: none;
}

a.btn.cta.hover-underline:after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: var(--primary-color);
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
}

a.btn.cta.hover-underline:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
}